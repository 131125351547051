// React imports
import React, { useEffect, useState } from 'react';

// Third-party imports
import { Button, Card, Form, Spinner, Stack, Table } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ScrollableContent } from 'src/components/ScrollableContent';
import { IResourceProject } from 'src/models/Project';
import {
  fetchAssignedProjectsOfResource,
  getProjectsOfResource,
  updateProjectStatus,
} from 'src/redux/features/projects/projectsSlice';

// App imports
import { TableLoader } from 'src/views/TimeSheet/components/TableLoader';

const ManageProjects = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const columns = ['Project', 'Status'];

  const { data: projects, loading: projectsLoading } = useSelector(
    getProjectsOfResource,
  );
  const [data, setData] = useState<IResourceProject[]>([]);

  const [isSaving, setIsSaving] = useState(false);
  const onStatusChange = (index) => {
    const clonedData = data.map((it) => {
      return { ...it };
    });

    clonedData[index].status = !clonedData[index].status;
    setData([...clonedData]);
  };

  const getResourceProjects = async (resourceEmail) => {
    await dispatch(
      fetchAssignedProjectsOfResource({ resource: resourceEmail }),
    );
  };

  const onSave = async () => {
    setIsSaving(true);

    const response = await dispatch(
      updateProjectStatus({
        id: 0,
        payload: {
          activities: data.map((it) => {
            return {
              ...it,
            };
          }),
        },
      }),
    );

    if (response != null) {
      toast.success('Project(s) status updated successfully');
    }

    setIsSaving(false);

    getResourceProjects(auth.user.profile.email);
  };

  useEffect(() => {
    if (!projects.length) getResourceProjects(auth.user.profile.email);
    if (projects.length > 0) setData(projects);
  }, [projects]);

  return (
    <Card bg="light" text="dark" className="h-100 bg-white border-0 shadow">
      <Card.Header className="bg-white my-1 border-0">
        <Stack direction="horizontal" className="justify-content-between">
          <span className="fs-4">Projects</span>
        </Stack>
      </Card.Header>
      <Card.Body className="h-full pt-0 overflow-hidden">
        <ScrollableContent minHeight="100%" maxHeight="100%">
          {projectsLoading ? <TableLoader /> : null}
          {!projectsLoading ? (
            <Table striped>
              <thead
                className="position-sticky top-0 bg-white"
                style={{ zIndex: 1000 }}
              >
                <tr className="border-1">
                  {columns.map((col, index) => (
                    <th
                      colSpan={6}
                      className="text-start fw-bolder p-3"
                      key={`${col}-${index}`}
                    >
                      {col}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.map((row, index) => (
                  <tr
                    key={row.projectMappingID}
                    className="text-start border-1"
                  >
                    <td colSpan={6} className="fw-bold p-3">
                      {row?.projectName}
                    </td>
                    <td colSpan={6} className="p-3">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label=""
                        className="mt-2"
                        defaultChecked={row?.status}
                        onChange={() => onStatusChange(index)}
                      />
                    </td>
                  </tr>
                ))}
                {!data?.length ? (
                  <tr className="p-5">
                    <td colSpan={12} className="p-5">
                      No Data available
                    </td>
                  </tr>
                ) : (
                  ''
                )}
              </tbody>
            </Table>
          ) : null}
        </ScrollableContent>
      </Card.Body>
      <Card.Footer className="bg-white">
        <Stack
          direction="horizontal"
          gap={2}
          className="justify-content-end mb-2"
        >
          <Button
            className="text-white"
            disabled={!data?.length}
            onClick={onSave}
          >
            {isSaving ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2 border-1 text-dark"
                />
                Saving...
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Stack>
      </Card.Footer>
    </Card>
  );
};

export default ManageProjects;
