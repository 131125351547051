import React from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';

// import Home from './views/Home';
import TimeSheet from './views/TimeSheet';
import { hasAuthParams, useAuth } from 'react-oidc-context';
// import NavBar from './components/Navbar';
import Layout from './views/Layout';
import Dashboard from './views/Dashboard';
import ManageResource from './views/ManageResources';
import ResourceList from './views/ManageResources/List';
import AddProject from './views/ManageResources/AddProject';
import MonthlySheet from './views/MonthlySheet';
import { FullScreenLoader } from './components/FullScreenLoader';
import EditProject from './views/ManageResources/EditProject';
import HasAccess from './components/HasAccess';
import ActivitiesList from './views/ManageActivities/List';
import { useSelector } from 'react-redux';
import { getUserRoles } from 'src/redux/features/users/usersSlice';
import ManageActivities from './views/ManageActivities';
import ManageProjects from './views/ManageProjects';

function App(): JSX.Element {
  const auth = useAuth();
  const userRoles = useSelector(getUserRoles);
  const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

  // automatically sign-in
  React.useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading) {
    return <FullScreenLoader showLoader title="Signing you in/out..." />;
  }

  if (!auth.isAuthenticated) {
    return <FullScreenLoader showLoader={false} title="Unable to log in." />;
  }

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route path="" element={<TimeSheet />} />
          <Route path="/timesheet" element={<TimeSheet />} />
          <Route
            path="/team-timesheet"
            element={
              <HasAccess
                userRole={userRoles.data}
                allowedRoles={['CapexAdmin', 'CapexAdManager']}
              >
                <TimeSheet teamTime={true} />
              </HasAccess>
            }
          />
          <Route
            path="/monthlysheet"
            element={
              <HasAccess
                userRole={userRoles.data}
                allowedRoles={['CapexAdmin']}
              >
                <MonthlySheet />
              </HasAccess>
            }
          />
          {/* <Route path="/manage-resource" element={<ManageResource />}> */}
          {/* <Route
            path="/manage-resource"
            element={
              <HasAccess
                userRole={userRoles.data}
                allowedRoles={['CapexAdmin']}
              >
                <ManageResource />
              </HasAccess>
            }
          >
            <Route path="" element={<ResourceList />} />
            <Route path="list" element={<ResourceList />} />
            <Route path="edit/:id" element={<EditProject />} />
          </Route> */}
          <Route path="/manage-resource/add" element={<AddProject />} />
          <Route
            path="/manage-activities"
            element={
              <HasAccess
                userRole={userRoles.data}
                allowedRoles={['CapexAdmin', 'CapexProjectLeader']}
              >
                <ManageActivities />
              </HasAccess>
            }
          >
            <Route path="" element={<ActivitiesList isModalOpen={false} />} />
            <Route
              path="list"
              element={<ActivitiesList isModalOpen={false} />}
            />
          </Route>
          <Route path="/manage-projects" element={<ManageProjects />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
