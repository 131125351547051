// React imports
import React, { useState } from 'react';

// Third party imports
import { Button, Dropdown, Image, Nav, Navbar } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';

// App imports
import Logo from '../../assets/logo.png';
import AccountTitle from './AccountTitle';
import { parseRoles } from 'src/utils/helpers';
import { actions } from 'src/redux/features/session/slice';
import { useDispatch } from 'react-redux';
// import { client } from 'src/utils/api';

const NavBar = () => {
  const auth = useAuth();
  const dispatch = useDispatch();

  const [roles] = useState<string[]>(
    parseRoles(auth?.user?.profile?.role as string),
  );

  const toggleMenu = () => {
    dispatch(actions.setOffCanvasMenu());
  };

  const onLogout = async () => {
    await auth.signoutSilent();
  };

  return (
    <Navbar fixed="top" bg="primary" className="py-2 px-4" variant="dark">
      <Button
        className="d-xs-block d-sm-block d-md-block d-lg-none p-0 me-2"
        variant="link"
        onClick={toggleMenu}
      >
        <i className="bi bi-list text-white fs-4"></i>
      </Button>
      <Navbar.Brand href="/">
        <Image src={Logo} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto "></Nav>
        <Nav>
          <Dropdown className="me-4" style={{ width: '260px' }} align="end">
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-basic"
              className={`d-flex flex-row align-items-center w-100 bg-transparent border-0`}
            >
              <AccountTitle userName={auth?.user?.profile?.name} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={onLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
