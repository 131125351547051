import { Stack, InputGroup, Form, Button } from 'react-bootstrap';

// App imports
import { WeekPicker } from '../WeekPicker';
import { Link } from 'react-router-dom';
import ResourceFilterHoc from './ResourceFilterHoc';

const TimesheetFilters = ({
  weekDate,
  onWeekChange,
  onWorkWeekChange,
  onReporteeChange,
  weekType,
  teamTime,
}) => {
  const onUserChange = (userEmail) => {
    onReporteeChange(userEmail);
  };
  const weekStyle = {
    width: teamTime ? '130px' : 'auto',
  };

  const AddProjectStyle = {
    minWidth: '115px',
  };

  return (
    <Stack
      direction="horizontal"
      className="justify-content-between p-3"
      gap={3}
    >
      <span className="fs-4 me-2">Timesheet</span>
      <InputGroup
        className="bg-white w-auto me-auto border border-1 border-lightgray"
        style={{ borderRadius: '6px' }}
      >
        <span
          style={{
            display: 'flex',
            borderRadius: '5px',
            border: '.5px solid lightgray',
          }}
        >
          <InputGroup.Text
            id="basic-addon1"
            className="bg-white border-end-0"
            style={{ border: '2px' }}
          >
            <i className="bi bi-calendar-week"></i>
          </InputGroup.Text>
          <WeekPicker
            startDate={weekDate}
            onWeekChange={onWeekChange}
            weekType={weekType}
          />
        </span>
      </InputGroup>

      {teamTime ?? (
        <Link to="/manage-resource/add" className="me-2">
          <Button className="text-white" style={AddProjectStyle}>
            Add Project
          </Button>
        </Link>
      )}

      <Form.Select
        aria-label="Default select Week Type"
        onChange={onWorkWeekChange}
        style={weekStyle}
      >
        <option disabled>Select Week Type</option>
        <option selected={weekType === 'week'} value="week">
          Week
        </option>
        <option selected={weekType === 'work_week'} value="work_week">
          Work Week
        </option>
      </Form.Select>

      <ResourceFilterHoc teamTime={teamTime} onUserChange={onUserChange} />
    </Stack>
  );
};

export { TimesheetFilters };
